import React from "react";
import { SiAutodeskrevit, SiAutocad } from "react-icons/si";
import { MdRateReview } from "react-icons/md";
import { PiPipeFill } from "react-icons/pi";
import { FaBimobject, FaFileInvoiceDollar } from "react-icons/fa";

const OurServices = [
  {
    id: "gtm-bim",
    img: "./3d-bim-black.svg",
    title: "BIM Collaboration",
    description: "We make live updates to your BIM model as your project develops.",
    href: "/services/bim-modelling",
    icon: <SiAutodeskrevit size={60} />
  },
  {
    id: "gtm-time",
    img: "./4d-bim-black.svg",
    title: "Design Reviews",
    description: "Conduct design reviews with the BIM model to ensure constructability and coordination.",
    href: "/services/design-reviews",
    icon: <MdRateReview size={60} />
  },
  {
    id: "gtm-cost",
    img: "./5d-bim-black.svg",
    title: "2D Drafting",
    description: "Develop detailed construction drawings with typical details and annotations.",
    href: "/services/construction-drawings",
    icon: <SiAutocad size={60} />
  },
  {
    id: "gtm-clash",
    img: "./clash-detection-black.png",
    title: "Multiple Disciplines",
    description: "Develop ACMV, Electrical, Fire Protection, and Structural models for clash detection.",
    href: "/services/clash-detection",
    icon: <PiPipeFill size={60} />
  },
  {
    id: "gtm-verify",
    img: "./as-built-verification-black.svg",
    title: "OpenBIM",
    description: "Export native BIM models as IFC files for open collaboration.",
    href: "/services/ifc-submissions",
    icon: <FaBimobject size={60} />
  },
  {
    id: "gtm-scan",
    img: "./scan-bim-black.svg",
    title: "BIM Software",
    description: "Enable 4D/5D/6D BIM workflows with our modern technology infrastructure",
    href: "https://blog.bim.com.sg/applications-of-drone-photogrammetry-in-construction-sites/",
    icon: <FaFileInvoiceDollar size={60} />
  }
];

export default OurServices;
