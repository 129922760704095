import React, { useState } from "react";
import PlaybookforContractors from "../components/_Landing/BIMServices";
import SEO from "../components/Seo";

const Services = () => {
  const [showModal, setShowModal] = useState(true);
  const [category, setCategory] = useState<string | null>(null);
  return <PlaybookforContractors />;
};

export default Services;

export const Head = ({ location }) => (
  <SEO
    title={"Bimeco - The Leading BIM Services Company"}
    description={
      "Create CAD sheets, BIM models, Site Surveys, 4D schedules, and more with us.  "
    }
    pathname={location.pathname}
  />
);
