import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import CurlyArrow from "assets/svgs/curly-arrow.svg";

const Portfolio = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return (
        '<span class="' +
        className +
        '"><span class="pagination-inner"></span></span>'
      );
    },
  };

  const imagedata = useStaticQuery(graphql`
    {
      water: file(relativePath: { eq: "portfolio/water.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      rail: file(relativePath: { eq: "portfolio/rail.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      hospital: file(relativePath: { eq: "portfolio/hospital.png" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      airport: file(relativePath: { eq: "portfolio/airport.jpg" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  let projectData = [
    {
      src: imagedata.water,
      alt: "Water Reclamation Plant",
      title: "Water Reclamation Plant",
      description:
        "P&ID diagrams were integrated with the BIM model to ensure comprehensive documentation for the O&M phase.",
    },
    {
      src: imagedata.rail,
      alt: "Rail Project - Cross Island Line",
      title: "Cross Island Line",
      description:
        "BIM models were used for 4D scheduling and 5D cost estimation, ensuring project delivery on time and within budget.",
    },
    {
      src: imagedata.hospital,
      alt: "Health Campus Project",
      title: "Health Campus Project",
      description:
        "Legacy CAD drawings were converted to BIM models for clash detection and coordination.",
    },
    {
      src: imagedata.airport,
      alt: "Airport Terminal",
      title: "Airport Terminal",
      description:
        "Scan-to-BIM services were used to create a mesh model and digital twin of the facility.",
    },
  ];
  ``;

  return (
    <section
      id="portfolio"
      className="pt-10 pb-6 overflow-hidden md:pt-16 bg-main-primary"
    >
      <div className="tw-container">
        <div className="flex justify-between items-start md:items-center md:flex-row flex-col gap-2.5 md:gap-0">
          <div className="flex items-center">
            <h2 className="text-3xl md:text-4xl text-gray-900 font-extrabold -tracking-[0.9px] mb-0">
              Our Portfolio
            </h2>
            <CurlyArrow className="hidden -mb-10 lg:ml-10 xl:ml-20 lg:block" />
          </div>
          <p className="text-left md:text-right max-w-[518px] text-gray-700 text-sm md:text-base mb-0">
            As a leading BIM service provider, we have worked on
            multiple integrated BIM delivery projects over the years.
          </p>
        </div>
        <div className="mt-6 md:mt-10 lg:mt-16">
          <Swiper
            slidesPerView={4}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            pagination={pagination}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
            }}
          >
            {projectData.map((project, index) => (
              <SwiperSlide key={index}>
                <div className="h-60">
                  <GatsbyImage
                    image={getImage(project.src)}
                    alt={project.alt}
                    className="w-full h-full rounded-2xl md:rounded-[20px] object-cover"
                  />
                </div>
                {project.title && (
                  <h3 className="text-2xl text-gray-900 font-extrabold -tracking-[0.9px] mb-0">
                    {project.title}
                  </h3>
                )}
                {project.title && (
                  <p className="text-gray-900 -tracking-[0.9px] mb-0">
                    {project.description}
                  </p>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
