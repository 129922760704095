import React, { useContext } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import MC3D from "assets/svgs/mingcute_cube-3d-line.svg";
import BiFolderX from "assets/svgs/bi_folder-x.svg";
import ArrowCube from "assets/svgs/arrow-cube.svg";
import BiFolder3D from "assets/svgs/bi_folder-3d.svg";
import VaddinChart3D from "assets/svgs/vaadin_chart-3d.svg";
import CurlyArrow from "assets/svgs/curly-arrow.svg";
import Compare from "components/Compare";
import Footer from "components/Footer";
import Feedback from "components/Feedback";
import Portfolio from "components/Portfolio";
import TwoColumn from "components/TwoColumn";
import Services from "./Services";
import { handleDefault } from "hooks/navigation";
import data from "./data.json";
import "swiper/css";

const PlaybookForContractors = () => {
  const imagedata = useStaticQuery(graphql`
    {
      schematic: file(relativePath: { eq: "schematic.webp" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      clash: file(relativePath: { eq: "clashdetection.webp" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      cde: file(relativePath: { eq: "cde.webp" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
      twothree: file(relativePath: { eq: "2dto3d.webp" }) {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  `);

  let sections = [
    {
      id: "gtm-bim",
      title: "BIM Collaboration",
      content: `Schedule weekly reviews with our BIM team. Subscribe to real-time updates to BIM & CAD files across your supply chain.`,
      linkText: null,
      linkHref:
        "https://blog.bim.com.sg/what-is-autodesk-bim-collaborate-pro-delivering-bim-for-your-project/",
      src: imagedata.schematic,
      bgColor: "bg-white",
    },
    {
      id: "gtm-review",
      title: "Virtual Clash Reviews",
      content: `Resolve issues such as clashes between disciplines. Keep track of changes to the BIM model and ensure that project stakeholders are on track for delivery.`,
      linkText: "Benefits of Virtual Reviews",
      linkHref: "https://blog.bim.com.sg/idd-ice-meetings/",
      src: imagedata.clash,
    },
    {
      id: "gtm-iso",
      title: "2D & 3D BIM",
      content: `We create detailed construction drawings directly from BIM, ensuring seamless updates to 2D drawings in alignment with the BIM model. Create comprehensive BIM submission packages that include both 3D & 2D documents.`,
      linkText: "2D CAD vs 3D BIM",
      linkHref:
        "https://blog.bim.com.sg/why-updating-revit-models-doesnt-always-update-2d-shop-drawings/",
      src: imagedata.twothree,
    },
    {
      id: "gtm-review",
      title: "Integrated Digital Delivery",
      content:
        "An ISO 19650-compliant CDE is a central repository for all project information. It ensures that all project stakeholders have access to the latest project information, facilitating 4D, 5D, and 6D BIM workflows.",
      linkText: "Benefits of 4D BIM",
      linkHref:
        "https://blog.bim.com.sg/what-is-4d-bim-and-how-can-it-transform-the-construction-management-process/",
      src: imagedata.cde,
    },
  ];

  return (
    <div id="gtm-top">
      {/* Header */}
      <main>
        {/* Hero Section */}
        <section className="pt-10 md:pt-[57px] pb-9 bg-white">
          <div className="tw-container">
            <div className="relative z-0">
              <BiFolderX className="absolute top-0 hidden left-10 md:block -z-10" />
              <ArrowCube className="absolute left-0 -bottom-[130px] w-[340px] md:block hidden -z-10" />
              <BiFolder3D className="absolute top-0 hidden right-10 md:block -z-10" />
              <VaddinChart3D className="absolute bottom-0 right-0 hidden md:block -z-10" />
              <div className="text-center max-w-[810px] mx-auto">
                <label className="inline-flex items-center gap-1 py-1 px-2 md:px-3 rounded-full bg-main-primary text-xs text-black font-medium tracking-[0.3px] uppercase mb-4 md:mb-6">
                  <MC3D />
                  BIM Services
                </label>
                <h1 className="mb-5 text-4xl font-extrabold text-black md:text-5xl">
                  BIM Services
                </h1>
                <div className="text-gray-800">
                  Create CAD sheets, BIM models, Drone Surveys, 4D
                  schedules, and more. We provide the people,
                  technology, and workflows to enable BIM-based
                  project delivery.
                </div>
                <div className="flex flex-col items-center justify-center gap-2 mt-5 md:mt-8 lg:mt-10 md:gap-4">
                  <button
                    type="button"
                    className="transition-all duration-300 ease-in-out hover:opacity-80 text-sm md:text-base text-black font-medium capitalize rounded-md shadow-shadow-sm bg-main-primary py-[13px] px-4 md:px-[25px]"
                    onClick={() => navigate(`#bim-packages`)}
                  >
                    Our Packages
                  </button>
                  <Link
                    to="#bim-workflows"
                    className="text-sm hover:underline cursor-pointer"
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
            <div className="mt-10 md:mt-16 lg:mt-[90px]">
              <p className="text-gray-500 text-sm font-semibold tracking-[0.35px] uppercase text-center">
                Companies We Work With
              </p>
              <div className="mx-auto mt-10 grid grid-cols-4 items-center gap-x-12 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-12 lg:max-w-none lg:grid-cols-5 ">
                <StaticImage
                  src={`../../../assets/images/logos/abb.webp`}
                  alt="abb"
                  objectFit="cover"
                  loading="lazy"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 grayscale"
                />
                <StaticImage
                  src={`../../../assets/images/logos/sj.webp`}
                  alt="abb"
                  objectFit="cover"
                  loading="lazy"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 grayscale"
                />
                <StaticImage
                  src={`../../../assets/images/logos/shmz.webp`}
                  alt="abb"
                  objectFit="cover"
                  loading="lazy"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 grayscale"
                />
                <StaticImage
                  src={`../../../assets/images/logos/hdb.webp`}
                  alt="abb"
                  objectFit="cover"
                  loading="lazy"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 grayscale"
                />
                <StaticImage
                  src={`../../../assets/images/logos/bentley.webp`}
                  alt="abb"
                  objectFit="cover"
                  loading="lazy"
                  className="col-span-2 max-h-12 w-full object-contain lg:col-span-1 grayscale"
                />
              </div>
            </div>
          </div>
        </section>
        {/* Past Projects */}

        {/* Our Services */}
        <Services id="bim-workflows" />

        {/* Feedback */}
        <Feedback />

        {/* Why US */}
        <TwoColumn
          title="BIM Workflows"
          description="Enhance project delivery with integrated BIM delivery"
          sections={sections}
        />

        {/* PORTFOLIO */}
        <Portfolio />

        {/* FAQ */}
        <section className="py-10 md:py-16 bg-gray-50" id="faq">
          <div className="tw-container">
            <h2 className="text-3xl md:text-4xl text-black font-extrabold -tracking-[0.9px] text-left mb-0">
              Frequently asked questions
            </h2>

            <div className="pb-5 mt-5 space-y-5 border-0 border-b border-gray-200 border-solid md:mt-6 lg:mt-8 md:pb-6 lg:pb-8 md:space-y-6 lg:space-y-8">
              {data.faq.map((faq) => (
                <div
                  className="flex flex-col items-start gap-3 pt-4 border-0 border-t border-gray-200 border-solid md:gap-6 lg:gap-8 md:pt-5 lg:pt-6 md:flex-row"
                  key={faq.id}
                >
                  <div className="md:w-[300px] lg:w-[488px]">
                    <p className="mb-0 text-base font-semibold text-black ">
                      {faq.question}
                    </p>
                  </div>
                  <div className="flex-1">
                    <p className="mb-0 text-sm text-gray-500 md:text-base">
                      {faq.answer}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-col items-start justify-between gap-4 pt-5 mt-5 border-0 border-t border-gray-200 border-solid md:mt-6 lg:mt-8 md:items-center lg:gap-10 pd:mt-6 lg:pt-8 md:flex-row">
              <h5 className="mb-0 text-base font-medium text-black md:text-xl">
                Can’t find the answer you’re looking for? Reach out to
                our customer support team.
              </h5>

              <CurlyArrow className="hidden lg:block" />
              <button
                onClick={() => handleDefault()}
                className="transition-all duration-300 ease-in-out hover:opacity-80 text-sm md:text-base text-white font-medium capitalize rounded-md shadow-shadow-sm bg-black py-[13px] px-4 md:px-[25px] shrink-0"
              >
                Get In Touch
              </button>
            </div>
          </div>
        </section>

        <section>
          <Compare />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default PlaybookForContractors;
